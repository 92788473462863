import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Card, CardGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="home" />
      <Container>
        <h1>Press Release</h1>
        <CardGroup>
          {data.allWpPost.nodes.map(node => (
            <Card bg="light" text="dark" style={{ width: '18rem' }} key={node.slug}>
              <Card.Img variant="top" src={node.featuredImage?.node?.sourceUrl} />
              <Card.Body>
                <Card.Title>
                  <Link to={node.slug}>
                    <p>{node.title}</p>
                  </Link>
                </Card.Title>
                {/* <div dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                <p>test</p>
                <p>{node.categories?.nodes?.name}</p>
                <h1>{node.categories?.nodes?.name}</h1>
                <Card.Footer className="text-muted">{node.date}</Card.Footer>
              </Card.Body>

            </Card>
          ))}
        </CardGroup>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
{
  allWpPost(
    sort: {fields: [date], order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "pressrelease"}}}}}
  ) {
    nodes {
      title
      excerpt
      content
      slug
      date(formatString: "MMMM Do, YYYY")
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      tags {
        nodes {
          name
        }
      }
    }
  }
}
`